import * as React from "react"
import Layout from "../components/common/Layout"
import Meta from "../components/common/Meta"
import Main from "../components/common/Main"
import PageIntro from "../components/common/intro/PageIntro"

export const Head = () => <Meta title="404" description="Page not found" />

const NotFoundPage = () => {
  const title = [["404"]]
  return (
    <Layout
      notfound={true}
      style={{ display: "flex", minHeight: "100vh", flexDirection: "column" }}
    >
      <Main>
        <PageIntro title={title} subheading="This page doesn't exist." />
      </Main>
    </Layout>
  )
}

export default NotFoundPage
